<template>
  <div>
    <Navigator />
    <FixedBtn />
    <div>
      <div class="B1" id="up">
        <p class="B1T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"
          data-aos-delay="100">
          Providing solutions to grow your business
        </p>
        <div class="B1F2 showM" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"
          data-aos-delay="300">
          <agile :options="opcionesD">
            <div class="car_slide">
              <a href="https://es.ford.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/FORD_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I2"
                  src="../assets/home/MAGNA_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.bmw.com/en/index.html" target="_blank" rel="noopener noreferrer"><img class="B1F2_I3"
                  src="../assets/home/BMW_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="http://www.des-ign.co.za" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/DESIGN_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.globalvehicle.ca" target="_blank" rel="noopener noreferrer"><img class="B1F2_I2"
                  src="../assets/home/GLOBAL_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.flex-n-gate.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I3"
                  src="../assets/home/FLEX_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.cntrline.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/CENTERL_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.gm.com.mx/" target="_blank" rel="noopener noreferrer"><img class="B1F2_I2"
                  src="../assets/home/GM_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://legacy-ind.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I3"
                  src="../assets/home/LEGACY_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/MASSIV_SL.png" /></a>
            </div>
          </agile>
        </div>
        <div class="B1F2 showD" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"
          data-aos-delay="300">
          <agile :options="opcionesD">
            <div class="car_slide">
              <a href="https://es.ford.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/FORD_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I2"
                  src="../assets/home/MAGNA_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.bmw.com/en/index.html" target="_blank" rel="noopener noreferrer"><img class="B1F2_I3"
                  src="../assets/home/BMW_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="http://www.des-ign.co.za" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/DESIGN_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.globalvehicle.ca" target="_blank" rel="noopener noreferrer"><img class="B1F2_I2"
                  src="../assets/home/GLOBAL_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.flex-n-gate.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I3"
                  src="../assets/home/FLEX_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.cntrline.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/CENTERL_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.gm.com.mx/" target="_blank" rel="noopener noreferrer"><img class="B1F2_I2"
                  src="../assets/home/GM_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://legacy-ind.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I3"
                  src="../assets/home/LEGACY_SL.png" /></a>
            </div>
            <div class="car_slide">
              <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B1F2_I1"
                  src="../assets/home/MASSIV_SL.png" /></a>
            </div>
          </agile>
        </div>
        <p class="B1T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"
          data-aos-delay="500">
          Our goal is to become a leading technological company in hardware
          controls and software solutions.
        </p>
      </div>

      <div class="B2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="800">
        <div class="B2F1">
          <div class="B2Line"></div>
          <p class="B2F1_T1">About the <span>company</span></p>
          <p class="B2F1_T2">
            <span>DAVISIND GROUP</span> is a Mexican company that provides
            engineering and software solutions to our customers in the
            automotive, mining, food and beverage industry.
          </p>
          <p class="B2F1_T2">
            We provide to our clients with
            <span>the best in technological innovation</span> to get an
            automated process in the most efficient and effective way.
          </p>
        </div>
        <img class="showM" src="../assets/home/ROBOT_MV.png" />
        <img class="showD" src="../assets/home/ROBOT_DT.png" />
      </div>

      <div class="B3">
        <p class="B3T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          We provide <span>technology solutions</span> to grow your business
        </p>
        <p class="B3T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          Would you like to start? we got you!
        </p>
        <div class="B3F3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <a href="https://davisindgroup.com/contact">
            <p>Let´s start talking</p>
          </a>
        </div>
      </div>

      <div class="B4">
        <p class="B4T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          Our services
        </p>
        <p class="B4T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          What <span>we do</span>
        </p>
        <hr />
        <div class="serviciosdesglo">
          <div class="B4Line" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"></div>

          <div class="B4F1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <img class="B4F1_I1 showM" src="../assets/home/HARDWARE_MV.png" />
            <img class="B4F1_I1 showD" src="../assets/home/HARDWARE_DT.png" />
            <div class="B4F1_F1">
              <div class="B4F1_F1F1">
                <img class="showM" src="../assets/home/ICONH_MV.png" />
                <img class="showD" src="../assets/home/ICONH_DT.png" />
                <p>Hardware Controls System Design</p>
              </div>
              <p class="B4F1_F1F2">
                We provide complete design of the automated systems for
                Automotive, Mining and food and beverage.
              </p>
              <a href="/what-we-do" class="a-tag">
                <div class="B4F1_F1F3">
                  <p>MORE</p>
                  <img src="../assets/home/BlueRight_MV.png" />
                </div>
              </a>
            </div>
          </div>

          <div class="B4F2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <img class="B4F2_I1 showM" src="../assets/home/SOFTWARE_MV.png" />
            <img class="B4F2_I1 showD" src="../assets/home/SOFTWARE_DT.png" />
            <div class="B4F2_F1">
              <div class="B4F2_F1F1">
                <img class="showM" src="../assets/home/ICONS_MV.png" />
                <img class="showD" src="../assets/home/ICONS_DT.png" />
                <p>Controls Software Design</p>
              </div>
              <p class="B4F2_F1F2">
                Davis-Ind Group controls team develops a complete safety and
                quality system. Software design, integration, start up, debug
                and support.
              </p>
              <a href="/what-we-do" class="a-tag">
                <div class="B4F2_F1F3">
                  <p>MORE</p>
                  <img src="../assets/home/BlueRight_MV.png" />
                </div>
              </a>
            </div>
          </div>

          <div class="B4F3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <img class="B4F3_I1 showM" src="../assets/home/ROBOTICS_MV.png" />
            <img class="B4F3_I1 showD" src="../assets/home/ROBOTICS_DT.png" />
            <div class="B4F3_F1">
              <div class="B4F3_F1F1">
                <img class="showM" src="../assets/home/ICONR_MV.png" />
                <img class="showD" src="../assets/home/ICONR_DT.png" />
                <p>Robotics</p>
              </div>
              <p class="B4F3_F1F2">
                To make sure our costumers receive the best in robotic engineer,
                we include on our process initial start up and debug field
                support.
              </p>
              <a href="/what-we-do" class="a-tag">
                <div class="B4F3_F1F3">
                  <p>MORE</p>
                  <img src="../assets/home/BlueRight_MV.png" />
                </div>
              </a>
            </div>
          </div>

          <div class="B4F4" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <img class="B4F4_I1 showM" src="../assets/home/TOOLING_MV.png" />
            <img class="B4F4_I1 showD" src="../assets/home/TOOLING_DT.png" />
            <div class="B4F4_F1">
              <div class="B4F4_F1F1">
                <img class="showM" src="../assets/home/ICONT_MV.png" />
                <img class="showD" src="../assets/home/ICONT_DT.png" />
                <p>Tooling and Assembly</p>
              </div>
              <p class="B4F4_F1F2">
                Davis-Ind Group controls team develops a complete safety and
                quality system. Software design, integration, start up, debug
                and support.
              </p>
              <a href="/what-we-do" class="a-tag">
                <div class="B4F4_F1F3">
                  <p>MORE</p>
                  <img src="../assets/home/BlueRight_MV.png" />
                </div>
              </a>
            </div>
          </div>

          <div class="B4F5" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <img class="B4F5_I1 showM" src="../assets/home/INSTALL_MV.png" />
            <img class="B4F5_I1 showD" src="../assets/home/INSTALL_DT.png" />
            <div class="B4F5_F1">
              <div class="B4F5_F1F1">
                <img class="showM" src="../assets/home/ICONI_MV.png" />
                <img class="showD" src="../assets/home/ICONI_DT.png" />
                <p>Install & Commissioning</p>
              </div>
              <p class="B4F5_F1F2">
                It is very important to us to make sure that our machines are
                tested with the best processes to give our clients high quality
                and reliable services.
              </p>
              <a href="/what-we-do" class="a-tag">
                <div class="B4F5_F1F3">
                  <p>MORE</p>
                  <img src="../assets/home/BlueRight_MV.png" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="serviciosdire">
          <div class="fila1sd">
            <div class="bloservi">
              <a href="https://davisindgroup.com/what-we-do">
                <img src="../assets/icos1.svg" alt="Hardware Controls System Design" />
                <p>Hardware Controls System Design</p>
              </a>
            </div>
            <div class="bloservi">
              <a href="https://davisindgroup.com/what-we-do">
                <img src="../assets/icos2.png" alt="Controls Software Design" />
                <p>Controls Software Design</p>
              </a>
            </div>
            <div class="bloservi">
              <a href="https://davisindgroup.com/what-we-do">
                <img src="../assets/icos3.svg" alt="Robotics" />
                <p>Robotics</p>
              </a>
            </div>
          </div>
          <div class="fila2sd">
            <div class="bloservi">
              <a href="https://davisindgroup.com/what-we-do">
                <img src="../assets/icos4.svg" alt="Tooling and Assembly" />
                <p>Install & Commissioning</p>
              </a>
            </div>
            <div class="bloservi">
              <a href="https://davisindgroup.com/what-we-do">
                <img src="../assets/icos5.svg" alt="Install & Commissioning " />
                <p>Tooling and Assembly</p>
              </a>
            </div>
            <div class="bloservi">
              <a href="https://davisindgroup.com/what-we-do">
                <img src="../assets/icos6.svg" alt="Install & Commissioning " />
                <p>Vision System</p>
              </a>
            </div>
          </div>
          <div class="blobtnservi">
            <a href="https://davisindgroup.com/what-we-do">Read more</a>
          </div>
        </div>
      </div>

      <div class="B5">
        <p class="B5T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          Our CLIENTS
        </p>
        <p class="B5T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <span>Trusted</span> Customers
        </p>
        <div class="B5Line" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"></div>

        <div class="B5IMGS showM">
          <div class="B5F0" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="http://www.des-ign.co.za" target="_blank" rel="noopener noreferrer"><img class="B5F1_I1"
                src="../assets/home/DESIGN_MV.png" /></a>

            <a href="https://www.sodecia.com/" target="_blank" rel="noopener noreferrer"><img class="B5F1_I2"
                src="../assets/home/sodecia.png" /></a>

            <a href="https://www.globalvehicle.ca" target="_blank" rel="noopener noreferrer"><img class="B5F1_I2"
                src="../assets/home/GLOBAL_MV.png" /></a>
          </div>
          <div class="B5F1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://es.ford.com" target="_blank" rel="noopener noreferrer"><img class="B5F2_I1"
                src="../assets/home/FORD_MV.png" /></a>

            <a href="https://www.bmw.com/en/index.html" target="_blank" rel="noopener noreferrer"><img class="B5F2_I2"
                src="../assets/home/BMW2_DT.png" /></a>

            <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B5F3_I2"
                src="../assets/home/MASSIV_MV.png" /></a>

          </div>
          <div class="B5F3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B5F3_I1"
                src="../assets/home/MAGNA_MV.png" /></a>
            <a href="https://www.gm.com.mx/" target="_blank" rel="noopener noreferrer"><img class="B5F2_I3"
                src="../assets/home/GM_MV.png" /></a>
            <a href="https://www.cntrline.com" target="_blank" rel="noopener noreferrer"><img class="B5F3_I3"
                src="../assets/home/centerlmv.png" /></a>
          </div>
          <div class="B5F2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://legacy-ind.com" target="_blank" rel="noopener noreferrer"><img class="B5F4_I1"
                src="../assets/home/LEGACY_MV.png" /></a>
            <a href="https://www.flex-n-gate.com" target="_blank" rel="noopener noreferrer"><img class="B5F4_I2"
                src="../assets/home/FLEX_MV.png" /></a>
          </div>
          <div class="B5F4" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://www.fft.de/en/" target="_blank" rel="noopener noreferrer"><img class="B5F4_I1"
                src="../assets/FFT1.png" /></a>
            <a href="https://www.prodomax.com" target="_blank" rel="noopener noreferrer"><img class="B5F4_I2"
                src="../assets/prodo.png" /></a>
          </div>
        </div>

        <div class="B5IMGS showD">
          <div class="B5F1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="http://www.des-ign.co.za" target="_blank" rel="noopener noreferrer"><img class="B5F1_I1"
                src="../assets/home/DESIGN_DT.png" /></a>
            <a href="https://www.globalvehicle.ca" target="_blank" rel="noopener noreferrer"><img class="B5F1_I2"
                src="../assets/home/GLOBAL_DT.png" /></a>
            <a href="https://www.flex-n-gate.com" target="_blank" rel="noopener noreferrer"><img class="B5F1_I3"
                src="../assets/home/FLEX_DT.png" /></a>
          </div>
          <div class="B5F2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://es.ford.com" target="_blank" rel="noopener noreferrer"><img class="B5F2_I1"
                src="../assets/home/FORD_DT.png" /></a>
            <a href="https://legacy-ind.com" target="_blank" rel="noopener noreferrer"><img class="B5F2_I3"
                src="../assets/home/LEGACY_DT.png" /></a>
            <a href="https://www.bmw.com/en/index.html" target="_blank" rel="noopener noreferrer"><img class="B5F3_I3"
                src="../assets/home/BMW2_DT.png" /></a>
          </div>
          <div class="B5F3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B5F3_I1"
                src="../assets/home/MAGNA_DT.png" /></a>
            <a href="https://www.magna.com" target="_blank" rel="noopener noreferrer"><img class="B5F3_I2"
                src="../assets/home/MASSIV_DT.png" /></a>
            <a href="https://www.cntrline.com" target="_blank" rel="noopener noreferrer"><img class="B5F3_I3"
                src="../assets/home/centerl.png" /></a>
          </div>
          <div class="B5F4" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
            <a href="https://www.sodecia.com/" target="_blank" rel="noopener noreferrer"><img class="B5F1_I2"
              src="../assets/home/sodecia.png" /></a>
            <a href="https://www.gm.com.mx" target="_blank" rel="noopener noreferrer"><img class="B5F2_I2"
              src="../assets/home/GM_DT.png" /></a>
            <a href="https://www.fft.de/en/" target="_blank" rel="noopener noreferrer"><img class="B5F2_I1"
                src="../assets/FFT1.png" /></a>
            <a href="https://www.prodomax.com" target="_blank" rel="noopener noreferrer"><img class="B5F2_I2"
                src="../assets/prodo.png" /></a>
          </div>
        </div>
      </div>

      <div class="B6">
        <p class="B6T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          Our BLOG
        </p>
        <p class="B6T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          Latest <span>posts</span>
        </p>
        <div class="B6Line" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"></div>

        <div class="B6Content showM" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <carousel :per-page="1" :mouse-drag="true" :navigation-enabled="true" :navigationNextLabel="nextB"
            :navigationPrevLabel="nextA" :loop="false" :navigate-to="inicial" :paginationEnabled="false">
            <slide v-for="(item, key) in blogs" :key="key">
              <a :href="'blog/' + item.url">
                <div class="B6Slide">
                  <img class="B6I1" :src="srcImg(item.image)" alt="" />
                  <p class="B6T3">
                    {{ item.title }}
                  </p>
                  <p class="B6T4">{{ item.dateblog }}</p>
                  <p class="B6T5">By {{ item.autor_name + ' ' + item.autor_lastname }}</p>
                  <p class="B6T6">
                    {{ item.descrp }}
                  </p>
                  <a :href="'blog/' + item.url" class="a-tag">
                    <div class="B6F5">
                      <p>MORE</p>
                      <img src="../assets/home/BlueRight_MV.png" />
                    </div>
                  </a>
                </div>
              </a>
            </slide>
          </carousel>
        </div>

        <div class="B6Content showD" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <div class="dflx">
            <a v-for="(item, key) in blogs" :key="key" :href="'blog/' + item.url">
              <div class="B6Slide">
                <img class="B6I1" :src="srcImg(item.image)" alt="" />
                <p class="B6T3">
                  {{ item.title }}
                </p>
                <p class="B6T4">{{ convertdate(item.dateblog) }}</p>
                <p class="B6T5">By {{ item.autor_name + ' ' + item.autor_lastname }}</p>
                <p class="B6T6">
                  {{ item.descrp }}
                </p>
                <a :href="'blog/' + item.url" class="a-tag">
                  <div class="B6F5">
                    <p>MORE</p>
                    <img src="../assets/home/BlueRight_MV.png" />
                  </div>
                </a>
              </div>
            </a>

          </div>
        </div>
      </div>

      <div class="B7">
        <p class="B7T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          CONTACT US
        </p>
        <p class="B7T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          Ready <span>to grow?</span>
        </p>
        <div class="B7Line" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600"></div>

        <div class="B7Content" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="B7B1 showM" src="../assets/home/READY_MV.png" />
          <div class="B7B2">
            <p class="B7B2_T1 B7T1_D">Head Quarters</p>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_HOME.png" />
              </div>
              <p class="B7B2_T2">
                Avenida Tabasco #227, Colonia San Benito, Hermosillo, Sonora, Mex. CP 83190
              </p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_MESSAGE.png" />
              </div>

              <p class="B7B2_T2">contact@davisindgroup.com</p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_PHONE.png" />
              </div>

              <p class="B7B2_T2">+521 (662) 688 1302</p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_LINKEDIN.png" />
              </div>

              <p class="B7B2_T2">Linkedin</p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_QUALITY.png" />
              </div>
              <p class="B7B2_T2">
                <a href="https://davisindgroup.com/quality-policy">Quality Policy</a>
              </p>
            </div>

            <p class="B7B2_T1 B7T2_D">US Branch</p>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_HOME.png" />
              </div>

              <p class="B7B2_T2">
                8839 So Redwood Rd Suite D West Jordan,UT,84088
              </p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_MESSAGE.png" />
              </div>

              <p class="B7B2_T2">contact@davisindgroup.com</p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_PHONE.png" />
              </div>

              <p class="B7B2_T2">+1 (385) 900 9436</p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_LINKEDIN.png" />
              </div>

              <p class="B7B2_T2">Linkedin</p>
            </div>
            <div class="B7B2_F2">
              <div class="img-ic">
                <img src="../assets/home/ICON_QUALITY.png" />
              </div>

              <p class="B7B2_T2">
                <a href="https://davisindgroup.com/quality-policy">Quality Policy</a>
              </p>
            </div>
          </div>
          <img class="B7B1 showD" src="../assets/home/READY_DT.png" />
          <div class="B7B3">
            <input type="text" placeholder="Name" v-model="name" />
            <input type="text" placeholder="Phone" v-model="phone" />
            <input type="email" placeholder="Mail" v-model="email" />
            <input type="text" placeholder="City" v-model="city" />
            <textarea placeholder="Message" v-model="mensaje"></textarea>
            <div class="advise-cont">
              <div v-if="this.status == 'success'">
                <p class="cart_msg cart_success">{{ this.msg }}</p>
              </div>
              <div v-if="this.status == 'error'">
                <p class="cart_msg cart_error">{{ this.msg }}</p>
              </div>
            </div>
            <div class="B7B3_F6" @click="enviarMail2(name, phone, email, city, mensaje)">
              <p>Send Message</p>
              <img src="../assets/home/WhiteRight_MV.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapActions } from "vuex";
import Navigator from "../components/Navigator";
import Footer from "../components/Footer.vue";
import FixedBtn from "../components/FixedBtn.vue";

import { url, endpointCodes } from '../global/index';

import es from 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'


export default {
  name: "Home",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      city: "",
      mensaje: "",
      status: "",
      msg: "",
      nextA: '<div class="divPrev0"><p class="NButton">&#8592;</p></div>',
      nextB: '<div class="divPrev0"><p class="NButton">&#8594;</p></div>',
      inicial: 0,
      posts: [
        { imagenP: require("../assets/home/LASTEST_MV.png") },
        { imagenP: require("../assets/home/LASTEST_MV.png") },
        { imagenP: require("../assets/home/LASTEST_MV.png") },
      ],

      opcionesD: {
        infinite: true,
        autoplay: true,
        slidesToShow: 3,
        navButtons: false,
        dots: false,
      },
      /*posts: [
        { imagenP: require("../assets/home/LASTEST_DT.png") },
        { imagenP: require("../assets/home/LASTEST2_DT.png") },
        { imagenP: require("../assets/home/LASTEST3_DT.png") },
        { imagenP: require("../assets/home/LASTEST_DT.png") },
        { imagenP: require("../assets/home/LASTEST2_DT.png") },
        { imagenP: require("../assets/home/LASTEST3_DT.png") },
      ],*/
      convertdate: function (date) {
        const dayjs = require('dayjs');
        dayjs.extend(relativeTime)
        let newsdata = dayjs(date).locale(es).format('YYYY-MMMM-DD')
        let fecha = newsdata.split('-')
        let dd = fecha[2]
        let mm = fecha[1]
        let yy = fecha[0]
        return fecha = mm + ' ' + dd + ', ' + yy
      },
    };
  },
  components: {
    Navigator,
    Footer,
    FixedBtn,
  },
  async created() {
    await this.getAllInfoBlo("blog");
  },
  computed: {
    blogs() {
      let arr = this.$store.getters["blog/data"];

      if (!Array.isArray(arr)) {
        return [];
      }

      let array = []

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].statusblog == 'VISIBLE') {
          array.push(arr[i])
        }

      }

      return array.slice(0, 3);
    },
  },
  methods: {
    ...mapActions("blog", ["getAllInfoBlo"]),
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 5000);
    },
    delMsgs: function () {
      this.status = "";
      this.msg = "";
    },

    srcImg: function (img) {
      let src = `${url}/${endpointCodes.get}/blog-img/${img}`;

      return src;
    },
    enviarMail2: async function (name, phone, email, city, mensaje) {
      this.msg = "";
      this.status = "";
      if (name == undefined || name == "" || name == null) {
        this.msg = "Name field is not valid.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "Phone field is not valid.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "Email is not valid.";
        this.status = "error";
      } else if (city == undefined || city == "" || city == null) {
        this.msg = "Service field is not valid.";
        this.status = "error";
      } else if (mensaje == undefined || mensaje == "" || mensaje == null) {
        this.msg = "Message field is not valid.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          city: city,
          mensaje: mensaje,
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto2",
          data
        );

        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          this.phone = "";
          this.email = "";
          this.city = "";
          this.mensaje = "";
          this.delStatus();
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
};
</script>

<style scoped>
/* Servicios desglosdos ocultos */
.serviciosdesglo {
  display: none;
}

.a-tag {
  text-decoration-color: transparent;
}

.B1 {
  background-image: url("../assets/home/Header_MV.png");
  width: 100%;
  height: 182.2429906542056vw;
  background-repeat: no-repeat;
  background-size: cover;
}

p.B1T1 {
  width: 71vw;
  font-size: 7.476635514018691vw;
  font-weight: 400;
  line-height: 9.457943925233645vw;
  color: var(--color-6);
  font-family: var(--MOSB_Regular);
  margin: 0vw;
  margin-left: 11.682242990654206vw;
  padding-top: 38.318vw;
}

.B1F2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75vw;
  margin: 6.5420560747663545vw auto 0;
}

img.B1F2_I1 {
  /*width: 18.457943925233643vw;
  height: auto;*/
  min-height: 9.662vw;
  max-height: 9.662vw;
  width: auto;
  /*margin-right: 9.579439252336448vw;*/
}

img.B1F2_I2 {
  min-height: 9.662vw;
  max-height: 9.662vw;
  width: auto;
  /* margin-right: 12.616822429906541vw;*/
}

img.B1F2_I3 {
  min-height: 9.662vw;
  max-height: 9.662vw;
  width: auto;
}

p.B1T2 {
  width: 73.13084112149532vw;
  font-size: 3.5046728971962615vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  line-height: 4.964953271028037vw;
  color: var(--color-5);
  margin: auto;
  margin-top: 6.775700934579438vw;
}

.B2 {
  margin-bottom: 7.476635514018691vw;
}

.B2Line {
  width: 8.177570093457943vw;
  height: 0.3vw;
  background-color: var(--color-1);
  margin-top: 17.056074766355138vw;
}

.B2F1 {
  margin-left: 11.91588785046729vw;
  margin-bottom: 15.186915887850466vw;
}

p.B2F1_T1 {
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  color: var(--color-2);
  margin: 0vw;
  margin-top: 9.11214953271028vw;
  margin-bottom: 7.476635514018691vw;
}

.B2F1_T1 span {
  color: var(--color-1);
}

p.B2F1_T2 {
  width: 72vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  line-height: 4.995327102803738vw;
  margin: 0vw;
  margin-bottom: 5vw;
  color: var(--color-3);
}

.B2F1_T2 span {
  font-weight: 600;
}

.B2 img {
  width: 80.8411214953271vw;
  height: 62.149532710280376vw;
  display: flex;
  margin: auto;
}

.B3 {
  margin-bottom: 33.8785046728972vw;
  background-image: url("../assets/home/DI_MV.png");
  background-repeat: no-repeat;
  background-size: 54.90654205607477vw;
  background-position-x: right;
  padding-top: 26vw;
}

p.B3T1 {
  width: 69vw;
  font-size: 6.074766355140187vw;
  font-weight: 400;
  line-height: 7.684579439252337vw;
  font-family: var(--MOSB_Light);
  margin: auto;
  color: var(--color-1);
  text-align: center;
  margin-bottom: 10.2803738317757vw;
}

.B3T1 span {
  color: var(--color-2);
}

p.B3T2 {
  width: 49vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  margin: auto;
  color: var(--color-3);
  line-height: 4.995327102803738vw;
  text-align: center;
  margin-bottom: 5.841121495327103vw;
}

.B3F3 {
  display: flex;
  width: 58.41121495327103vw;
  height: 15.42056074766355vw;
  background-color: var(--color-2);
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}

.B3F3 p {
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--MOSB_Light);
  margin: auto;
  color: var(--color-5);
}

p.B4T1 {
  font-size: 1.6355140186915886vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  width: fit-content;
  margin: auto;
  letter-spacing: 1.3vw;
  margin-bottom: 7.710280373831775vw;
}

.B4 hr {
  background: #06c5ff !important;
  height: 1px;
  border: none !important;
  width: 11.682242990654206vw;
}

p.B4T2 {
  width: 69vw;
  font-size: 6.074766355140187vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  margin: auto;
  color: var(--color-2);
  text-align: center;
  margin-bottom: 8.177570093457943vw;
}

.B4T2 span {
  color: var(--color-1);
}

.B4Line {
  width: 7.710280373831775vw;
  height: 0.3vw;
  background-color: var(--color-1);
  margin: auto;
  margin-bottom: 15.887850467289718vw;
}

img.B4F1_I1 {
  display: flex;
  width: 93.45794392523365vw;
  height: auto;
  margin: auto;
}

.B4F1_F1F1 {
  display: flex;
  margin-left: 13.785046728971961vw;
  align-items: center;
  margin-top: 17.990654205607477vw;
}

.B4F1_F1F1 img {
  width: 14.719626168224298vw;
  height: 14.719626168224298vw;
  margin-right: 9.345794392523365vw;
}

.B4F1_F1F1 p {
  width: 37vw;
  font-size: 3.9719626168224296vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  line-height: 5.0233644859813085vw;
  margin: 0vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.B4F1_F1F2 {
  width: 49vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  margin-left: 38.31775700934579vw;
  color: var(--color-3);
  line-height: 4.995327102803738vw;
  margin-bottom: 6.5420560747663545vw;
}

.B4F1_F1F3 {
  display: flex;
  align-items: center;
  margin-left: 38.31775700934579vw;
  margin-bottom: 18.925233644859812vw;
}

.B4F1_F1F3 p {
  font-size: 2.570093457943925vw;
  font-family: var(--MOSB_Regular);
  font-weight: 400;
  color: var(--color-2);
  margin: 0vw;
  margin-right: 3.9719626168224296vw;
}

.B4F1_F1F3 img {
  width: 13.317757009345794vw;
  height: auto;
}

img.B4F2_I1 {
  display: flex;
  width: 93.45794392523365vw;
  height: auto;
  margin: auto;
}

.B4F2_F1F1 {
  display: flex;
  margin-left: 13.785046728971961vw;
  align-items: center;
  margin-top: 17.990654205607477vw;
}

.B4F2_F1F1 img {
  width: 14.719626168224298vw;
  height: 14.719626168224298vw;
  margin-right: 9.345794392523365vw;
}

.B4F2_F1F1 p {
  width: 37vw;
  font-size: 3.9719626168224296vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  line-height: 5.0233644859813085vw;
  margin: 0vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.B4F2_F1F2 {
  width: 49vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  margin-left: 38.31775700934579vw;
  color: var(--color-3);
  line-height: 4.995327102803738vw;
  margin-bottom: 6.5420560747663545vw;
}

.B4F2_F1F3 {
  display: flex;
  align-items: center;
  margin-left: 38.31775700934579vw;
  margin-bottom: 18.925233644859812vw;
}

.B4F2_F1F3 p {
  font-size: 2.570093457943925vw;
  font-family: var(--MOSB_Regular);
  font-weight: 400;
  color: var(--color-2);
  margin: 0vw;
  margin-right: 3.9719626168224296vw;
}

.B4F2_F1F3 img {
  width: 13.317757009345794vw;
  height: auto;
}

img.B4F3_I1 {
  display: flex;
  width: 93.45794392523365vw;
  height: auto;
  margin: auto;
}

.B4F3_F1F1 {
  display: flex;
  margin-left: 13.785046728971961vw;
  align-items: center;
  margin-top: 17.990654205607477vw;
}

.B4F3_F1F1 img {
  width: 14.719626168224298vw;
  height: 14.719626168224298vw;
  margin-right: 9.345794392523365vw;
}

.B4F3_F1F1 p {
  width: 37vw;
  font-size: 3.9719626168224296vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  line-height: 5.0233644859813085vw;
  margin: 0vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.B4F3_F1F2 {
  width: 49vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  margin-left: 38.31775700934579vw;
  color: var(--color-3);
  line-height: 4.995327102803738vw;
  margin-bottom: 6.5420560747663545vw;
}

.B4F3_F1F3 {
  display: flex;
  align-items: center;
  margin-left: 38.31775700934579vw;
  margin-bottom: 18.925233644859812vw;
}

.B4F3_F1F3 p {
  font-size: 2.570093457943925vw;
  font-family: var(--MOSB_Regular);
  font-weight: 400;
  color: var(--color-2);
  margin: 0vw;
  margin-right: 3.9719626168224296vw;
}

.B4F3_F1F3 img {
  width: 13.317757009345794vw;
  height: auto;
}

img.B4F4_I1 {
  display: flex;
  width: 93.45794392523365vw;
  height: auto;
  margin: auto;
}

.B4F4_F1F1 {
  display: flex;
  margin-left: 13.785046728971961vw;
  align-items: center;
  margin-top: 17.990654205607477vw;
}

.B4F4_F1F1 img {
  width: 14.719626168224298vw;
  height: 14.719626168224298vw;
  margin-right: 9.345794392523365vw;
}

.B4F4_F1F1 p {
  width: 37vw;
  font-size: 3.9719626168224296vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  line-height: 5.0233644859813085vw;
  margin: 0vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.B4F4_F1F2 {
  width: 49vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  margin-left: 38.31775700934579vw;
  color: var(--color-3);
  line-height: 4.995327102803738vw;
  margin-bottom: 6.5420560747663545vw;
}

.B4F4_F1F3 {
  display: flex;
  align-items: center;
  margin-left: 38.31775700934579vw;
  margin-bottom: 18.925233644859812vw;
}

.B4F4_F1F3 p {
  font-size: 2.570093457943925vw;
  font-family: var(--MOSB_Regular);
  font-weight: 400;
  color: var(--color-2);
  margin: 0vw;
  margin-right: 3.9719626168224296vw;
}

.B4F4_F1F3 img {
  width: 13.317757009345794vw;
  height: auto;
}

img.B4F5_I1 {
  display: flex;
  width: 93.45794392523365vw;
  height: auto;
  margin: auto;
}

.B4F5_F1F1 {
  display: flex;
  margin-left: 13.785046728971961vw;
  align-items: center;
  margin-top: 17.990654205607477vw;
}

.B4F5_F1F1 img {
  width: 14.719626168224298vw;
  height: 14.719626168224298vw;
  margin-right: 9.345794392523365vw;
}

.B4F5_F1F1 p {
  width: 37vw;
  font-size: 3.9719626168224296vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  line-height: 5.0233644859813085vw;
  margin: 0vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.B4F5_F1F2 {
  width: 49vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  margin-left: 38.31775700934579vw;
  color: var(--color-3);
  line-height: 4.995327102803738vw;
  margin-bottom: 6.5420560747663545vw;
}

.B4F5_F1F3 {
  display: flex;
  align-items: center;
  margin-left: 38.31775700934579vw;
  margin-bottom: 18.925233644859812vw;
}

.B4F5_F1F3 p {
  font-size: 2.570093457943925vw;
  font-family: var(--MOSB_Regular);
  font-weight: 400;
  color: var(--color-2);
  margin: 0vw;
  margin-right: 3.9719626168224296vw;
}

.B4F5_F1F3 img {
  width: 13.317757009345794vw;
  height: auto;
}

/* Bloque Servicios resumido */
.bloservi {
  margin-bottom: 8.878504672897195vw;
}

.serviciosdire {
  padding-top: 15.654205607476634vw;
  padding-bottom: 24.065420560747665vw;
}

.blobtnservi {
  text-align: center;
}

.bloservi a {
  display: flex;
  padding-left: 14.719626168224298vw;
  text-decoration: none;
}

.bloservi img {
  width: 9.345794392523365vw;
  height: 9.345794392523365vw;
}

.bloservi a p {
  font-size: 2.803738317757009vw;
  color: #676467;
  padding-left: 8.411214953271028vw;
}

.blobtnservi {
  margin-top: 25vw;
}

.blobtnservi a {
  background: #06c5ff;
  padding: 5.14018691588785vw 18vw;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 3.2710280373831773vw;
  font-family: "MADE Outer Sans Light";
  font-weight: 400;
}

.B5 {
  background-image: url("../assets/home/TRUSTED_MV.png");
  width: 100%;
  height: 171.9626168224299vw;
  background-repeat: no-repeat;
  background-size: cover;
}

p.B5T1 {
  font-size: 1.6355140186915886vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  width: fit-content;
  margin: auto;
  letter-spacing: 1.3vw;
  margin-bottom: 7.710280373831775vw;
  padding-top: 16.355140186915886vw;
  color: var(--color-5);
}

p.B5T2 {
  width: 69vw;
  font-size: 6.074766355140187vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  margin: auto;
  color: var(--color-6);
  text-align: center;
  margin-bottom: 8.177570093457943vw;
}

.B5T2 span {
  color: var(--color-5);
}

.B5Line {
  width: 11.682242990654206vw;
  height: 0.3vw;
  background-color: var(--color-5);
  margin: auto;
  margin-bottom: 17.056074766355138vw;
}

.B5IMGS {
  width: fit-content;
  margin: auto;
}

.B5F1 {
  display: flex;
  margin-bottom: 13.317757009345794vw;
}

img.B5F1_I1 {
  width: auto;
  height: 9.329vw;
  /*margin-right: 19.35514vw;*/
}

img.B5F1_I2 {
  width: auto;
  height: 9.329vw;
}

.B5IMGS {
  width: 67.708vw;
  margin: auto;
}

.B5F0 {
  width: 100%;
  margin-bottom: 9.345794392523365vw;
  display: flex;
  justify-content: space-between;
}

.B5F2 {
  width: 100%;
  margin-bottom: 9.345794392523365vw;
  display: flex;
  justify-content: space-between;
}

img.B5F2_I1 {
  width: auto;
  height: 9.329vw;
}

img.B5F2_I2 {
  width: auto;
  height: 9.329vw;
  /*  margin-left: 6vw;*/
}

img.B5F2_I3 {
  width: auto;
  height: 9.329vw;
  /*margin-right: -7vw;*/
}

img.B5F3_I3 {
  width: auto;
  height: 9.329vw;
}

.B5F3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.09375vw;
}

.B5F4 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14.252336448598129vw;
}

img.B5F3_I1 {
  width: auto;
  height: 9.329vw;
  /*margin-right: 19.859813084112147vw;*/
}

img.B5F3_I2 {
  width: auto;
  height: 9.329vw;
}

.B5F4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

img.B5F4_I1 {
  width: auto;
  height: 9.329vw;
  /*margin-right: 19.859813084112147vw;*/
}

img.B5F4_I2 {
  width: auto;
  height: 9.329vw;
}

.B6Content {
  width: 78.03738317757009vw;
  display: flex;
  justify-content: center;
  margin: auto;
}

.B6Content a {
  text-decoration-color: transparent;
}

p.B6T1 {
  font-size: 1.6355140186915886vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  width: fit-content;
  margin: auto;
  letter-spacing: 1.3vw;
  margin-bottom: 7.710280373831775vw;
  margin-top: 17.289719626168225vw;
  color: var(--color-3);
}

p.B6T2 {
  width: 69vw;
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  margin: auto;
  color: var(--color-2);
  text-align: center;
  margin-bottom: 8.177570093457943vw;
}

.B6T2 span {
  color: var(--color-1);
}

.B6Line {
  width: 11.682242990654206vw;
  height: 0.3vw;
  background-color: var(--color-1);
  margin: auto;
  margin-bottom: 11.448598130841122vw;
}

img.B6I1 {
  display: flex;
  width: 70.56074766355141vw;
  height: auto;
  margin: auto;
  margin-bottom: 8.878504672897195vw;
  margin-top: 3.7383177570093453vw;
}

p.B6T3 {
  display: flex;
  width: 60.51401869158879vw;
  font-family: var(--MOSB_Regular);
  font-size: 3.9719626168224296vw;
  font-weight: 400;
  line-height: 5.322429906542056vw;
  margin: auto;
  margin-bottom: 8.878504672897195vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.B6T4 {
  font-size: 3.0373831775700935vw;
  font-weight: 400;
  font-family: var(--MOSB_Light);
  color: var(--color-3);
  margin: 0vw;
  margin-left: 7.17757vw;
  margin-bottom: 2vw;
  text-transform: capitalize;
}

p.B6T5 {
  font-size: 3.0373831775700935vw;
  font-weight: 400;
  font-family: var(--MOSB_Light);
  color: var(--color-3);
  margin: 0vw;
  margin-left: 7.17757vw;
  margin-bottom: 7.476635514018691vw;
}

p.B6T6 {
  display: flex;
  width: 63.084112149532714vw;
  font-size: 3.2710280373831773vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  color: var(--color-3);
  margin: auto;
  margin-bottom: 5.841121495327103vw;
}

.B6F5 {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.B6F5 p {
  font-size: 2.570093457943925vw;
  font-weight: 400;
  font-family: var(--MOSB_Light);
  color: var(--color-2);
  margin: 0vw;
  margin-left: 8.177570093457943vw;
}

.B6F5 img {
  width: 13.317757009345794vw;
  height: auto;
  margin-left: 3.9719626168224296vw;
}

.divPrev0 {
  width: 12.383177570093459vw;
  height: 12.383177570093459vw;
  border-radius: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-2);
}

.B7 {
  background: #fbfbfb;
}

p.B7T1 {
  font-size: 1.6355140186915886vw;
  font-weight: 400;
  font-family: var(--OpenSans);
  width: fit-content;
  margin: auto;
  letter-spacing: 1.3vw;
  margin-bottom: 7.710280373831775vw;
  margin-top: 17.289719626168225vw;
  padding-top: 17.453vw;
  color: var(--color-3);
}

p.B7T2 {
  width: 69vw;
  font-size: 5.607476635514018vw;
  font-weight: 400;
  font-family: var(--MOSB_Regular);
  margin: auto;
  color: var(--color-2);
  text-align: center;
  margin-bottom: 8.177570093457943vw;
}

.B7T2 span {
  color: var(--color-1);
}

.B7Line {
  width: 11.682242990654206vw;
  height: 0.3vw;
  background-color: var(--color-1);
  margin: auto;
  margin-bottom: 11.448598130841122vw;
}

img.B7B1 {
  display: flex;
  width: 93.45794392523365vw;
  height: auto;
  margin: auto;
}

.B7B2 {
  width: 70vw;
  margin: auto;
  margin-bottom: 17.990654205607477vw;
}

p.B7B2_T1 {
  display: flex;
  font-size: 3.9719626168224296vw;
  font-family: var(--MOSB_Regular);
  font-weight: 400;
  color: var(--color-2);
  margin: 0vw;
  justify-content: flex-start;
  margin-bottom: 9vw;
  margin-top: 13.084112149532709vw;
}

.B7B2_F2 {
  display: flex;
  justify-content: left;
  align-items: baseline;
}

.B7B2_F2 .img-ic {
  width: 4.009vw;
  display: flex;
  padding-right: 4.245vw;
}

.B7B2_F2 .img-ic img {
  width: auto;
  height: 4.009vw;
  margin: auto;
}

p.B7B2_T2 {
  width: 62vw;
  font-size: 3.2710280373831773vw;
  font-family: var(--OpenSans);
  font-weight: 400;
  line-height: 6.876168224299066vw;
  color: var(--color-3);
  margin: 0vw;
}

.B7B3 input {
  display: flex;
  width: 71.981308vw;
  height: 17.289719626168225vw;
  margin: auto;
  margin-bottom: 5.607476635514018vw;
  border: 0.1vw solid var(--color-7);
  font-size: 3.2710280373831773vw;
  font-family: var(--OpenSans);
  color: var(--color-3);
  padding: 0vw 7vw;
}

.B7B3 textarea {
  display: flex;
  width: 71.981308vw;
  height: 34.36448598130841vw;
  margin: auto;
  margin-bottom: 3.0373831775700935vw;
  font-size: 3.2710280373831773vw;
  font-family: var(--OpenSans);
  color: var(--color-3);
  resize: none;
  border: 0.1vw solid var(--color-7);
  padding: 7vw 7vw;
}

.B7B3_F6 {
  display: flex;
  width: 85.74766355140187vw;
  height: 21.72897196261682vw;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #262ffd -16.53%, #0ca7ff 95.45%);
  margin-bottom: 24.299065420560748vw;
  cursor: pointer;
}

.B7B3_F6 p {
  font-size: 3.5046728971962615vw;
  font-weight: 400;
  font-family: var(--MOSB_Light);
  color: var(--color-5);
  margin: 0vw;
  margin-right: 10vw;
}

.B7B3_F6 img {
  width: 13.317757009345794vw;
  height: auto;
}

.B7 a {
  text-decoration: none;
  color: inherit;
}

.showD {
  display: none !important;
}

p.cart_msg {
  font-weight: 400;
  font-size: 4.206vw;
  line-height: 5.374vw;
  text-align: center;
  font-family: "MADE Outer Sans Regular";
  margin: 12vw 0 0;
  padding: 4vw 0;
}

p.cart_msg.cart_error {
  background: rgb(201, 83, 83);
  color: white;
}

p.cart_msg.cart_success {
  background: rgb(61, 107, 61);
  color: white;
}

.car_slide {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {

  /*-moz-animation-direction: ;.car_slide {
    display: flex;
    justify-content: space-between;
  }*/
  .B5F4 {
    width: 100%;
    margin-bottom: 9.345794392523365vw;
    display: flex;
    justify-content: space-between;
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  p.cart_msg {
    font-size: 0.706vw;
    line-height: 0.706vw;
    margin: 1vw 0 0;
    padding: 1vw;
  }

  .showD {
    display: block !important;
  }

  .showM {
    display: none !important;
  }

  .B1 {
    background-image: url("../assets/home/Header_DT.png") !important;
    height: 43.4375vw;
  }

  p.B1T1 {
    width: 27vw;
    font-size: 2.3958333333333335vw;
    line-height: 3.0307291666666667vw;
    margin-left: 8.28125vw;
    padding-top: 15.104vw;
  }

  .B1F2 {
    margin-top: 2.34375vw;
    margin-left: 8.28125vw;
    width: 25vw;
  }

  img.B1F2_I1 {
    width: auto;
    min-height: 3.438vw;
    max-height: 3.438vw;
    /*margin-right: 3.177083333333333vw;
    margin-bottom: 0.3vw;*/
  }

  img.B1F2_I2 {
    width: auto;
    min-height: 3.438vw;
    max-height: 3.438vw;
    /*margin-right: 3.802083333333333vw;
    margin-bottom: 0.8vw;*/
  }

  img.B1F2_I3 {
    width: auto;
    min-height: 3.438vw;
    max-height: 3.438vw;
  }

  p.B1T2 {
    width: 20.130841vw;
    font-size: 0.9375vw;
    line-height: 1.328125vw;
    margin-top: 2.7604166666666665vw;
    margin-left: 8.28125vw;
  }

  .B2 {
    display: flex;
    flex-flow: row-reverse;
    justify-content: left;
  }

  .B2 img {
    width: 41.197916666666664vw;
    height: 31.666666666666664vw;
    margin-top: 10.260416666666666vw;
    margin-left: 10.989583333333334vw;
    margin-right: 0vw;
  }

  .B2Line {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-top: 12.708333333333332vw;
  }

  p.B2F1_T1 {
    width: 9vw;
    font-size: 1.5625vw;
    line-height: 1.9765625000000002vw;
    margin-top: 3.3854166666666665vw;
    margin-bottom: 3.125vw;
  }

  .B2F1 {
    margin-left: 9.583333333333334vw;
    margin-bottom: 15.186915887850466vw;
  }

  p.B2F1_T2 {
    width: 25.65vw;
    font-size: 1.0416666666666665vw;
    line-height: 1.590625vw;
    margin-bottom: 2vw;
  }

  .B3 {
    margin-bottom: 18.28125vw;
    background-image: url("../assets/home/DI_DT.png");
    background-size: 35.67708333333333vw;
    padding-top: 13vw;
  }

  p.B3T1 {
    width: 58vw;
    font-size: 2.864583333333333vw;
    font-weight: 400;
    line-height: 3.6234375vw;
    font-family: var(--MOSB_Regular);
    margin: auto;
    color: var(--color-1);
    text-align: center;
    margin-bottom: 4vw;
  }

  p.B3T2 {
    width: 49vw;
    font-size: 1.0416666666666665vw;
    font-weight: 400;
    font-family: var(--OpenSans);
    margin: auto;
    color: var(--color-3);
    line-height: 1.590625vw;
    text-align: center;
    margin-bottom: 3vw;
  }

  .B3F3 {
    width: 17.239583333333332vw;
    height: 4.84375vw;
  }

  .B3F3 p {
    font-size: 0.9375vw;
    font-weight: 400;
    font-family: var(--MOSB_Light);
    margin: auto;
    color: var(--color-5);
  }

  p.B4T1 {
    font-size: 0.625vw;
    letter-spacing: 0.5vw;
    margin-bottom: 2.083333333333333vw;
    text-transform: uppercase;
  }

  p.B4T2 {
    font-size: 2.604166666666667vw;
    margin-bottom: 3.6979166666666665vw;
  }

  .B4Line {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-bottom: 14.6875vw;
  }

  .B4 hr {
    background: #06c5ff !important;
    height: 1px;
    border: none !important;
    width: 2.604166666666667vw;
  }

  .B4F1 {
    display: flex;
    justify-content: left;
  }

  img.B4F1_I1 {
    width: 43.072916666666664vw;
    margin-left: 5.15625vw;
    margin-right: 0vw;
  }

  .B4F1_F1F1 {
    margin-left: 9.0625vw;
    margin-top: 17.990654205607477vw;
  }

  .B4F1_F1F1 img {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-right: 3.6458333333333335vw;
  }

  .B4F1_F1F1 p {
    width: 15vw;
    font-size: 1.5625vw;
    line-height: 1.9765625000000002vw;
  }

  p.B4F1_F1F2 {
    width: 21vw;
    font-size: 1.0416666666666665vw;
    margin-left: 18.697916666666668vw;
    line-height: 1.590625vw;
    margin-bottom: 2.1354166666666665vw;
  }

  .B4F1_F1F3 {
    margin-left: 18.59375vw;
    margin-bottom: 20.260416666666668vw;
  }

  .B4F1_F1F3 p {
    font-size: 0.6770833333333334vw;
    margin-right: 0.8854166666666666vw;
  }

  .B4F1_F1F3 img {
    width: 2.96875vw;
  }

  .B4F2 {
    display: flex;
    justify-content: left;
    flex-flow: row-reverse;
  }

  img.B4F2_I1 {
    width: 43.072916666666664vw;
    margin-left: 8.75vw;
    margin-right: 0vw;
  }

  .B4F2_F1F1 {
    margin-left: 9.895833333333332vw;
    margin-top: 17.990654205607477vw;
  }

  .B4F2_F1F1 img {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-right: 3.6458333333333335vw;
  }

  .B4F2_F1F1 p {
    width: 9vw;
    font-size: 1.5625vw;
    line-height: 1.9765625000000002vw;
  }

  p.B4F2_F1F2 {
    width: 22vw;
    font-size: 1.0416666666666665vw;
    margin-left: 19.53125vw;
    line-height: 1.590625vw;
    margin-bottom: 2.1354166666666665vw;
  }

  .B4F2_F1F3 {
    margin-left: 19.53125vw;
    margin-bottom: 20.260416666666668vw;
  }

  .B4F2_F1F3 p {
    font-size: 0.6770833333333334vw;
    margin-right: 0.8854166666666666vw;
  }

  .B4F2_F1F3 img {
    width: 2.96875vw;
  }

  .B4F3 {
    display: flex;
    justify-content: left;
  }

  img.B4F3_I1 {
    width: 43.072916666666664vw;
    margin-left: 5.15625vw;
    margin-right: 0vw;
  }

  .B4F3_F1F1 {
    margin-left: 9.0625vw;
    margin-top: 17.990654205607477vw;
  }

  .B4F3_F1F1 img {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-right: 3.6458333333333335vw;
  }

  .B4F3_F1F1 p {
    width: 15vw;
    font-size: 1.5625vw;
    line-height: 1.9765625000000002vw;
  }

  p.B4F3_F1F2 {
    width: 21vw;
    font-size: 1.0416666666666665vw;
    margin-left: 18.697916666666668vw;
    line-height: 1.590625vw;
    margin-bottom: 2.1354166666666665vw;
  }

  .B4F3_F1F3 {
    margin-left: 18.59375vw;
    margin-bottom: 20.260416666666668vw;
  }

  .B4F3_F1F3 p {
    font-size: 0.6770833333333334vw;
    margin-right: 0.8854166666666666vw;
  }

  .B4F3_F1F3 img {
    width: 2.96875vw;
  }

  .B4F4 {
    display: flex;
    justify-content: left;
    flex-flow: row-reverse;
  }

  img.B4F4_I1 {
    width: 43.072916666666664vw;
    margin-left: 8.75vw;
    margin-right: 0vw;
  }

  .B4F4_F1F1 {
    margin-left: 9.895833333333332vw;
    margin-top: 17.990654205607477vw;
  }

  .B4F4_F1F1 img {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-right: 3.6458333333333335vw;
  }

  .B4F4_F1F1 p {
    width: 15vw;
    font-size: 1.5625vw;
    line-height: 1.9765625000000002vw;
  }

  p.B4F4_F1F2 {
    width: 22vw;
    font-size: 1.0416666666666665vw;
    margin-left: 19.53125vw;
    line-height: 1.590625vw;
    margin-bottom: 2.1354166666666665vw;
  }

  .B4F4_F1F3 {
    margin-left: 19.53125vw;
    margin-bottom: 20.260416666666668vw;
  }

  .B4F4_F1F3 p {
    font-size: 0.6770833333333334vw;
    margin-right: 0.8854166666666666vw;
  }

  .B4F4_F1F3 img {
    width: 2.96875vw;
  }

  .B4F5 {
    display: flex;
    justify-content: left;
  }

  img.B4F5_I1 {
    width: 43.072916666666664vw;
    margin-left: 5.15625vw;
    margin-right: 0vw;
  }

  .B4F5_F1F1 {
    margin-left: 9.0625vw;
    margin-top: 17.990654205607477vw;
  }

  .B4F5_F1F1 img {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-right: 3.6458333333333335vw;
  }

  .B4F5_F1F1 p {
    width: 15vw;
    font-size: 1.5625vw;
    line-height: 1.9765625000000002vw;
  }

  p.B4F5_F1F2 {
    width: 21vw;
    font-size: 1.0416666666666665vw;
    margin-left: 18.697916666666668vw;
    line-height: 1.590625vw;
    margin-bottom: 2.1354166666666665vw;
  }

  .B4F5_F1F3 {
    margin-left: 18.59375vw;
    margin-bottom: 20.260416666666668vw;
  }

  .B4F5_F1F3 p {
    font-size: 0.6770833333333334vw;
    margin-right: 0.8854166666666666vw;
  }

  .B4F5_F1F3 img {
    width: 2.96875vw;
  }

  /* Servicios home*/
  .serviciosdire {
    padding-top: 8.125vw;
    padding-bottom: 16.875vw;
    width: 65%;
    margin: auto;
  }

  .fila1sd,
  .fila2sd {
    display: flex;
    justify-content: center;
  }

  .fila2sd {
    margin: auto;
  }

  .bloservi {
    width: 100%;
  }

  .bloservi a {
    padding-left: 0;
    justify-content: center;
  }

  .bloservi img {
    width: 4.166666666666666vw;
    height: 4.166666666666666vw;
  }

  .bloservi a p {
    font-size: 0.8333333333333334vw;
    padding-left: 2.1875vw;
    width: 40%;
  }

  .blobtnservi a {
    width: 17.239583333333332vw;
    margin: auto;
    padding: 1.7708333333333333vw 5vw;
    font-size: 0.9375vw;
  }

  .B5 {
    background-image: url("../assets/home/TRUSTED_DT.png");
    height: 74.96875vw;
  }

  p.B5T1 {
    font-size: 0.625vw;
    padding-top: 8.802083333333334vw;
    letter-spacing: 0.5vw;
    margin-bottom: 2.083333333333333vw;
    text-transform: uppercase;
  }

  p.B5T2 {
    width: 69vw;
    font-size: 2.604166666666667vw;
    margin-bottom: 3.6979166666666665vw;
  }

  .B5Line {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-bottom: 6.979166666666667vw;
  }

  .B5F1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6.09375vw;
  }

  img.B5F1_I1 {
    width: 13.698vw;
    height: auto;
    /*width: 12.916666666666668vw;
    margin-right: 15.156249999999998vw;*/
  }

  img.B5F1_I2 {
    width: 13.698vw;
    height: auto;
    /*width: 12.916666666666668vw;
    margin-right: 13.072916666666668vw;*/
  }

  img.B5F1_I3 {
    width: 13.698vw;
    height: auto;
    /*width: 13.021vw;*/
  }

  .B5F2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6.09375vw;
  }

  img.B5F2_I1 {
    width: 13.698vw;
    height: auto;
    /*width: 9vw;
    margin-left: 2vw;*/
  }

  img.B5F2_I2 {
    width: 13.698vw;
    height: auto;
    /*width: 12.9vw;
    margin-left: 5.531vw;*/
  }

  img.B5F2_I3 {
    width: 13.698vw;
    height: auto;
    /*width: 13.020833333333334vw;
    margin-left: 6.917vw;
    margin-right: 4vw;*/
  }

  img.B5F3_I1 {
    width: 13.698vw;
    height: auto;
    /* width: 9.947916666666666vw;
    margin-left: 1.4vw;*/
    /* margin-right: 16.719vw;*/
  }

  img.B5F3_I2 {
    width: 13.698vw;
    height: auto;
    /*width: 13.385416666666666vw;*/
    /*margin-right: 11.302vw;*/
  }

  img.B5F3_I3 {
    width: 13.698vw;
    /*width: 15.312500000000002vw;*/
    height: auto;
    position: relative;
    /*margin-left: 7.5vw;*/
  }

  p.B6T1 {
    font-size: 0.625vw;
    padding-top: 8.802083333333334vw;
    letter-spacing: 0.5vw;
    margin-bottom: 2.083333333333333vw;
    text-transform: uppercase;
    margin-top: 0vw;
  }

  p.B6T2 {
    font-size: 2.604166666666667vw;
    margin-bottom: 3.6979166666666665vw;
  }

  .B6Line {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-bottom: 5.833333333333333vw;
  }

  .B6Content {
    width: 77.739583vw;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  img.B6I1 {
    width: 22.1875vw;
    margin-bottom: 3.0729166666666665vw;
    margin-top: 1.1979166666666667vw;
  }

  p.B6T3 {
    width: 17.514019vw;
    font-size: 1.1458333333333333vw;
    line-height: 1.5354166666666667vw;
    margin-bottom: 1.878505vw;
    margin-left: 2.5520833333333335vw;
  }

  p.B6T4 {
    font-size: 0.6770833333333334vw;
    margin-left: 2.7083333333333335vw;
    margin-bottom: 0.5vw;
  }

  p.B6T5 {
    font-size: 0.6770833333333334vw;
    margin-left: 2.7083333333333335vw;
    margin-bottom: 2.2916666666666665vw;
  }

  p.B6T6 {
    display: flex;
    width: 18.084112vw;
    font-size: 0.8333333333333334vw;
    font-weight: 400;
    font-family: var(--OpenSans);
    color: var(--color-3);
    margin: auto;
    margin-bottom: 1.841121vw;
    margin-left: 2.5520833333333335vw;
  }

  .B6F5 p {
    font-size: 0.6770833333333334vw;
    margin-left: 2.7083333333333335vw;
  }

  .B6F5 img {
    width: 2.96875vw;
    height: auto;
    margin-left: 0.8854166666666666vw;
  }

  p.B7T1 {
    font-size: 0.625vw;
    padding-top: 8.802083333333334vw;
    letter-spacing: 0.5vw;
    margin-bottom: 2.083333333333333vw;
    text-transform: uppercase;
    margin-top: 8.438vw;
    padding-top: 8.438vw;
  }

  p.B7T2 {
    font-size: 2.604166666666667vw;
    margin-bottom: 3.6979166666666665vw;
  }

  .B7Line {
    width: 2.604166666666667vw;
    height: 0.1vw;
    margin-bottom: 9.166666666666666vw;
  }

  .B7Content {
    display: flex;
    width: fit-content;
    margin: auto;
  }

  img.B7B1 {
    display: flex;
    width: 28.020833333333332vw;
    height: 42.135416666666664vw;
    margin: 0vw;
    margin-right: 1.40625vw;
    margin-left: 3vw;
  }

  .B7B2 {
    width: 21vw;
    margin: 0vw;
    margin-bottom: 17.990654205607477vw;
  }

  .B7B2_F2 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .B7B2_F2 .img-ic {
    width: 0.99vw;
    display: flex;
    padding-right: 1.406vw;
  }

  .B7B2_F2 .img-ic img {
    width: auto;
    height: 0.99vw;
    margin: auto;
  }

  p.B7B2_T1 {
    font-size: 1.3541666666666667vw;
    margin-bottom: 1.9791666666666665vw;
    margin-top: 0vw;
    margin-left: 2.5vw;
  }

  p.B7B2_T2 {
    width: 20vw;
    font-size: 0.9375vw;
    line-height: 1.9703125vw;
  }

  .B7B3 input {
    width: 21.3125vw;
    height: 3.854166666666667vw;
    margin-bottom: 1.25vw;
    font-size: 0.9375vw;
    padding: 0vw 2vw;
  }

  .B7B3 textarea {
    width: 21.3125vw;
    height: 11.7vw;
    margin-bottom: 1.25vw;
    font-size: 0.9375vw;
    padding: 1.4583333333333333vw 2vw;
  }

  .B7B3_F6 {
    width: 25.208333333333332vw;
    height: 4.84375vw;
    margin-bottom: 13.802083333333334vw;
  }

  .B7B3_F6 p {
    font-size: 0.9375vw;
    margin-right: 2vw;
  }

  .B7B3_F6 img {
    width: 2.96875vw;
  }

  /*.B7T1_D {
    margin-left: 5vw !important;
  }*/
  .B7T2_D {
    /*  margin-left: -11vw !important;*/
    margin-top: 8.25vw !important;
  }

  .blobtnservi {
    margin: 0;
  }

  .car_slide {}
}
</style>